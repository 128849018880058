import React, {useContext, useReducer, useEffect, useMemo, useCallback} from 'react';

import PropTypes from 'prop-types';

import {UserApplication} from '@application/User';
import {eventManager} from '@application/Event';
import {AuthApplication} from '@application/Auth';
import {useSettings} from '@components/_context';
import {useLoadUserOverallInfoInternetAware} from './_hooks/useLoadUserOverallInfoInternetAware';
import {UserEvent} from '@domain/model/Event';
import {COUNTRY} from '@domain/model/Site/Locale';
import {OrderStatus} from '@domain/model/Order';
import {mendelService} from '@mendel';
import {usePaymentInstrument, PaymentMethod} from '@lookiero/payments-front';
import {DEFAULT_SEGMENT} from '@domain/model/User/Segment';

const INITIAL_STATE = {
  WIPOrder: false,
  balance: 0,
  card: {},
  country: null,
  dateCreated: '',
  email: '',
  hasAnyOrders: false,
  id: null,
  loading: true,
  loadUserOverallInfo: async () => {},
  orders: [],
  nextBoxDate: '',
  estimatedCarrierDeliveryAt: '',
  personalCode: '',
  billingAddress: {},
  shippingAddress: {},
  subscription: {
    id: undefined,
    modality: undefined,
    lkDay: undefined,
  },
  username: '',
  personalShopper: {},
  referral: undefined,
  executeSkipMonth: () => null,
  resetState: () => null,
  segment: DEFAULT_SEGMENT,
  keepLoadingUserOverallInfo: () => {},
};

const POOLING_MAX_RETRIES = 10;

const UserOverallInfoContext = React.createContext(INITIAL_STATE);

const ACTIONS = {
  EXECUTE_ACTION_CHANGE_MODALITY_TO_SUBSCRIBER: 'execute-action-change-modality-to-subscriber',
  EXECUTE_ACTION_SKIP_MONTH: 'execute-action-skip-month',
  EXECUTE_ACTION_UPDATE_ORDER: 'execute-action-update-order',
  UPDATE_NEXT_BOX_DATE: 'update-next-box-date',
  UPDATE_ESTIMATED_CARRIER_DELIVERY_AT: 'update-estimated-carrier-delivery-at',
  LOAD_USER_OVERALL_INFO: 'load-user-overall-info',
  RESET_ACTION_EXECUTED: 'reset-action-executed',
  RESET_STATE: 'reset-state',
  SET_LOADING: 'set_loading',
  UPDATE_CARD: 'update-card',
  UPDATE_COUNTRY: 'update-country',
  LOAD_USER_OVERALL_INFO_WIP: 'load-user-overall-info-wip',
};

// TODO Albareto esto da mucho asco, si tuviera el culo menos apretado lo arreglaba pero hoy se queda así
const updateOrder = (state, {id, ...rest}) => {
  for (let i = 0, l = state.orders.length; i < l; i++) {
    const services = state.orders[i].services;
    for (let j = 0, ll = services.length; j < ll; j++) {
      if (services[j].id === id) {
        services[j] = {...services[j], ...rest};
      }
    }
  }
  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOAD_USER_OVERALL_INFO:
      return {...state, ...action.payload};
    case ACTIONS.LOAD_USER_OVERALL_INFO_WIP:
      return {...state, WIPOrder: true};
    case ACTIONS.UPDATE_CARD:
      return {...state, card: {...state.card, ...action.payload}};
    case ACTIONS.UPDATE_COUNTRY:
      return state.country === action.payload ? state : {...state, country: action.payload};
    case ACTIONS.EXECUTE_ACTION_UPDATE_ORDER:
      return {...updateOrder(state, action.payload)};
    case ACTIONS.UPDATE_NEXT_BOX_DATE:
      return {...state, nextBoxDate: action.payload};
    case ACTIONS.UPDATE_ESTIMATED_CARRIER_DELIVERY_AT:
      return {...state, estimatedCarrierDeliveryAt: action.payload};
    case ACTIONS.EXECUTE_ACTION_SKIP_MONTH:
    case ACTIONS.EXECUTE_ACTION_CHANGE_MODALITY_TO_SUBSCRIBER:
      return {...state, WIPOrder: true};
    case ACTIONS.RESET_ACTION_EXECUTED:
      return {...state, WIPOrder: false, ...action.payload};
    case ACTIONS.SET_LOADING:
      return {...state, loading: action.payload};
    case ACTIONS.RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

const removeNullablePropsFromObject = item =>
  Object.keys(item).reduce((acc, key) => {
    if (item[key] !== undefined && item[key] !== null) {
      acc[key] = item[key];
    }
    return acc;
  }, {});

// TODO repeated definition in some other place. Searh for it.
const ORDER_IN_PROGRESS_STATUSES = [
  OrderStatus.PEDIDO_REALIZADO,
  OrderStatus.EN_MARCHA,
  OrderStatus.BORRADOR,
  OrderStatus.EN_PREPARACION,
  OrderStatus.ENVIADO,
  OrderStatus.ENTREGADO,
  null,
];

const findWIPOrder = ({orders = []}) => {
  if (!orders.length) {
    return true;
  }

  const {
    services: {0: {shippingAddress, nextBoxDate} = {}},
  } = orders.find(({services: {0: order}}) => ORDER_IN_PROGRESS_STATUSES.includes(order.status)) || {services: []};

  return !shippingAddress || !nextBoxDate;
};

const getNextBoxDate = (orders = []) =>
  orders.reduce((date, services) => {
    const {
      services: {0: order},
    } = services;
    const orderNextBoxDate = (ORDER_IN_PROGRESS_STATUSES.includes(order.status) && order.nextBoxDate) || null;
    return date || orderNextBoxDate;
  }, null);

const getEstimatedCarrierDeliveryAt = (orders = []) =>
  orders.reduce((date, services) => {
    const {
      services: {0: order},
    } = services;
    const orderEstimatedCarrierDeliveryAt =
      (ORDER_IN_PROGRESS_STATUSES.includes(order.status) && order.estimatedCarrierDeliveryAt) || null;
    return date || orderEstimatedCarrierDeliveryAt;
  }, null);

const UserOverallInfoProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const {site, updateSettingsByCountry} = useSettings();
  const paymentInstrument = usePaymentInstrument();

  const loadUserOverallInfo = useCallback(
    async (loading = true) => {
      if (loading && !state.loading) {
        dispatch({type: ACTIONS.SET_LOADING, payload: loading});
      }
      const updatedUserInfo = await UserApplication.getUserOverallInfo();

      const sanitizedUserInfo = removeNullablePropsFromObject(updatedUserInfo);
      const userInfoWithNextBoxDate = {
        ...sanitizedUserInfo,
        nextBoxDate: getNextBoxDate(sanitizedUserInfo.orders),
        estimatedCarrierDeliveryAt: getEstimatedCarrierDeliveryAt(sanitizedUserInfo.orders),
      };

      eventManager.emit(UserEvent.USER_LOADED, userInfoWithNextBoxDate);
      dispatch({type: ACTIONS.LOAD_USER_OVERALL_INFO, payload: {...userInfoWithNextBoxDate, loading: false}});
    },
    [state.loading],
  );
  useLoadUserOverallInfoInternetAware({loadUserOverallInfo, loading: state.loading});

  const getUserOverallInfoPooling = async ({dispatch, retriesLeft = POOLING_MAX_RETRIES}) => {
    if (retriesLeft === 0) {
      return;
    }
    const nextValue = await UserApplication.getUserOverallInfo();
    const sanitizingValue = removeNullablePropsFromObject(nextValue);
    const WIPOrder = findWIPOrder(nextValue);
    if (WIPOrder) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      await getUserOverallInfoPooling({dispatch, retriesLeft: retriesLeft - 1});
    } else {
      sanitizingValue.nextBoxDate = getNextBoxDate(nextValue.orders);
      sanitizingValue.estimatedCarrierDeliveryAt = getEstimatedCarrierDeliveryAt(nextValue.orders);
      dispatch({type: ACTIONS.RESET_ACTION_EXECUTED, payload: sanitizingValue});
    }
  };
  const loadUserOverallInfoPooling = getUserOverallInfoPooling.bind(undefined, {dispatch});

  const value = useMemo(
    () => ({
      ...state,
      loadUserOverallInfo,
      keepLoadingUserOverallInfo: () => dispatch({type: ACTIONS.LOAD_USER_OVERALL_INFO_WIP}),
      updateOrder: async order => dispatch({type: ACTIONS.EXECUTE_ACTION_UPDATE_ORDER, payload: order}),
      updateNextBoxDate: date => dispatch({type: ACTIONS.UPDATE_NEXT_BOX_DATE, payload: date}),
      updateEstimatedCarrierDeliveryAt: date =>
        dispatch({type: ACTIONS.UPDATE_ESTIMATED_CARRIER_DELIVERY_AT, payload: date}),
      // TODO not used?
      updateCountry: country => dispatch({type: ACTIONS.UPDATE_COUNTRY, payload: country}),
      executeSkipMonth: () => dispatch({type: ACTIONS.EXECUTE_ACTION_SKIP_MONTH}),
      // TODO not used?
      executeChangeModalityToSubscriber: () => dispatch({type: ACTIONS.EXECUTE_ACTION_CHANGE_MODALITY_TO_SUBSCRIBER}),
      // TODO not used?
      executeChangeNextOrderDate: () => dispatch({type: ACTIONS.EXECUTE_ACTION_CHANGE_NEXT_ORDER_DATE}),
      resetState: () => dispatch({type: ACTIONS.RESET_STATE}),
      // TODO not used?
      updateCardInfo: card => dispatch({type: ACTIONS.UPDATE_CARD, payload: card}),
    }),
    [state, loadUserOverallInfo],
  );

  useEffect(() => {
    eventManager.subscribe(UserEvent.LOGIN, async () => await value.loadUserOverallInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (await AuthApplication.isLoggedIn()) {
        value.loadUserOverallInfo();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value.WIPOrder) {
      loadUserOverallInfoPooling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.WIPOrder]);

  useEffect(() => {
    // TODO Albareto In near future we will be able to remove this if which will provide true multilanguage/site but
    // now it is too risky to enable it for every country, so do it only for LU custumers
    if (COUNTRY.BE === site && COUNTRY.LU === value.country) {
      updateSettingsByCountry(value.country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.country]);

  useEffect(() => {
    if (!value.loading) {
      mendelService.deanonymize(state.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.loading]);

  useEffect(() => {
    value.updateCardInfo({
      cardLastNumbers:
        paymentInstrument.info && paymentInstrument.info.payment_method === PaymentMethod.CARD
          ? paymentInstrument.info.metadata.last4
          : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentInstrument.info]);

  return <UserOverallInfoContext.Provider value={value}>{children}</UserOverallInfoContext.Provider>;
};

UserOverallInfoProvider.propTypes = {
  children: PropTypes.node,
};

const useOverallInfo = () => useContext(UserOverallInfoContext) || {};

export {useOverallInfo, UserOverallInfoProvider, UserOverallInfoContext, getNextBoxDate, getEstimatedCarrierDeliveryAt};
